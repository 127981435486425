.closeSvg {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
}
.closeSvg:hover {
  cursor: pointer;
  opacity: .7;
}
.modalContent {
  display: grid;
  grid-template-columns: auto;
}
.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 40px 40px;
}
.content > img {
  margin-left: auto;
  margin-right: auto;
}
.title {
  font-size: 1.5em;
  margin-bottom: 0px;
  text-align: center;
  margin-left: auto;
  width: 272px;
  margin-right: auto;
}
.subtitle {
  font-size: var(--title-size);
  text-align: center;
  width: 272px;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
} 
.privacyAgreement {
  margin-left: 5px;
}

.privacyCheckbox {
  position: relative;
  vertical-align: middle;
  display: flex;
  align-items: flex-start;
  font-size: var(--description-size) !important;
}
.privacyCheckbox > span:nth-child(2) {
  line-height: 1.2;
}
.privacyCheckbox :global .ant-checkbox {
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0px;
}

.privacyCheckbox :global .ant-checkbox > .ant-checkbox-input {
  height: 24px;
  width: 24px;
}

.privacyCheckbox :global .ant-checkbox-inner::after {
  left: 31.5%;
}

.privacyCheckbox :global .ant-checkbox-inner {
  border: 1px solid black;
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  position: absolute;
  width: 24px;
}

.privacyCheckbox > span:nth-child(2) {
  max-width: 272px;
}

.emailForm {
  display: grid;
  gap: 24px;
  margin: 40px auto 0;
  width: 272px;
}
.emailForm :global .ant-form-item, .additionalForm :global .ant-form-item {
  margin: 0px;
}
.emailForm :global input {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  box-shadow: none !important;
  padding-left: 0px;
  padding-right: 0px;
}
.emailForm :global .ant-select:not(.ant-select-customize-input) .ant-select-selector, .additionalForm :global .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  box-shadow: none !important;
  padding-left: 0px;
  padding-right: 0px;
}
.emailForm :global .ant-select-single .ant-select-selector .ant-select-selection-search, .additionalForm :global .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  left: 0px;
}
.emailForm :global .ant-picker {
  width: 100%;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  box-shadow: none !important;
  padding-left: 0px;
  padding-right: 0px;
}

.emailForm :global .ant-form-item-explain {
  bottom: -2em;
  font-size: var(--description-size);
  position: absolute;
}

.boldLink {
  color: var(--color-black);
  font-family: ModerneLLWeb-Medium, sans-serif;
  text-decoration: none;
  margin-left: 5px;
}
.boldLink:hover {
  color: #919191;
}
.thankYouMessage {
  margin: auto;
  text-align: center;
}

.submitBtn {
  white-space: normal;
  height: auto;
}

@media(max-width: 767.5px) {
  .modalWrapper :global .ant-modal {
    max-width: 100vw;
    margin: 0;
    min-width: 100vw !important;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    min-height: 100vh;
  }
  .modalWrapper :global .ant-modal-content {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .photo {
    display: none;
  }
  .subtitle {
    font-size: var(--title-size);
  } 
  .modalContent {
    display: flex;
    flex-flow: column;
  }
  .content {
    padding: 40px 0px;
  }
  .contentPadded {
    padding-top: 40px;
  }
  .emailForm {
    width: 100%;
  }
  .emailForm :global .ant-form-item-control-input-content {
    text-align: start;
  }
  .emailForm :global input {
    width: 100%;
  }
  .emailForm :global .ant-form-item-explain {
    width: 100%;
    margin: auto;
  }
  .genderSelector :global .ant-radio-group {
    display: inline-flex;
  }
  .emailForm :global .ant-form-item .ant-select {
    width: 272px;
  }
  .emailForm :global .ant-row .ant-form-item {
    width: 272px;
    margin: 0 auto 24px;
  }
  .privacyWrapper {
    width: 272px;
    margin: auto;
    text-align: left;
  }
  .privacyCheckbox {
    text-align: left;
  }
  .privacyCheckbox {
    display: inline-flex;
  }
  .privacyCheckbox {
    max-width: 272px;
  }
}

@media(min-width: 768px) {
  .content {
    background-color: var(--color-white);
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    min-height: 636px;
    padding: 40px 20px;
  }
  .thankYouContent {
    min-height: unset;
  }
  .subtitle {
    max-width: 438px;
    font-size: 1rem;
  }

  .emailForm {
    max-width: inherit;
  }
}